import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom'; 
import { destroyStudent } from './store'


const Student = () => {
  const {students, schools} = useSelector(state => state)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  

  const destroy = async() => {
    await dispatch(destroyStudent(student))
    navigate('/students')
  }

  const student = students.find(student => {
    return student.id === params.id * 1
  })

  
  const enrolledSchool = (student, schools) => {
    return schools.find((school) => {
      if (school.id === student.schoolId ) {
      return true //filter returns boolean -- true = want item // false = don't want 
      } else {
        return false 
      }
    })
  }
// you have a student. Find which campus they go to



  console.log(schools)

  if (!student) {
    return null
  } // w/o error will throw when students dont load fast enough 
  
  
  const attends = enrolledSchool(student, schools)  // needs to be after if (!student) bc need to make sure there is definately a student 

  
  return (
    <div>
      <h2> { student.firstName } { student.lastName } </h2>
      <ul>
        <li> GPA: {student.gpa} </li>
        <li> Attends: { attends? attends.name : 'currently not enrolled' } </li>

      </ul>
      <button onClick= {destroy} >
        delete student 
      </button>
    </div>
  );
};

export default Student;
