import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'; 
import {destroySchool} from './store'

const School = () => {
  const {schools, students} = useSelector(state => state)
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  
  const destroy = async() => {
    await dispatch(destroySchool(school))
    navigate('/schools')
  }
  
  
  
  
  const school = schools.find(school => {  
    return school.id === params.id * 1
  })


function getStudents (students1, school) {
  let output = []
  for (let i = 0; i < students1.length; i++) {
    const student = students1[i] 
    if (student.schoolId === school.id) {
      output.push(student)  
    }
  }
  return output 
}
// you have a campus, find all the students that go to that campus 



  if (!school) {
    return null 
  } 
  

  const studentsHere = getStudents(students, school)


  return (
    <div>
      <h2> { school.name } </h2> 
      <ul>
        <li> Address: { school.address } </li>
      </ul>
      <img src= {school.imageUrl} />
      <h3> Enrolled Students </h3>
      <ul>
        { studentsHere.map((student) => {
          return <li key= { student.id } >
            { student.firstName }
          </li>
        })}
      </ul>
      <button onClick= {destroy}>
        delete school
      </button>
    </div>
  );
};

export default School;
