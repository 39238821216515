import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';  

const Students = () => {
  const {students, schools, courses} = useSelector(state => state)
  console.log(students)
  return (
    <div>
      <h2>Students List</h2>
      <ul>
        {
          students.map(student => {
            const school = schools.find((school) => {
              return school.id === student.schoolId
            }) // when we wanted school to display on the student
            console.log(school)
            return (
              <li key = {student.id} > 
                <Link to={`/students/${student.id}`} > {student.firstName} {student.lastName} </Link>
              </li>
            ); 
          })
        }
      </ul>
      <Link to="/students/add">Add a Student</Link>
    </div>
  );
};

export default Students;
