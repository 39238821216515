import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'; 



const Course = () => {
  const {courses} = useSelector(state => state)
  const params = useParams()


  const course = courses.find(course => {
    return course.id === params.id * 1
  })

  if (!course) {
    return null
  }

return (
  <div>
    <h2> { course.name } </h2>
    <ul>
      <li> Credit Hours: {course.creditHours} </li>
      <li> Location: {course.location} </li>
    </ul>
  </div>
)
}

export default Course;