import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { createCourse } from './store';

const CourseCreate = () => {
  const [name, setName] = useState('')
  const [creditHours, setCreditHours] = useState(0)
  const [location, setLocation] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const create = async(ev) => {
    ev.preventDefault()
    await dispatch(createCourse({name, creditHours, location}))
    navigate('/courses')

  }
return (
  <div>
    <h2>Creating a new course</h2>
    <form onSubmit= { create } >
      <input placeholder='name here' value= { name } onChange= { (ev) => {setName(ev.target.value)} } />
      <input placeholder='Credit Hours here' value= { creditHours } onChange= { (ev) => {setCreditHours(ev.target.value)} } />
      <input placeholder='location here' value= { location } onChange= { (ev) => {setLocation(ev.target.value)} } />
      <button disabled= { !name } > create </button>
    </form>
  </div>
)
};

export default CourseCreate;