import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { createSchool } from './store';


const SchoolCreate = () => {
  const [name, setName] = useState('') 
  const [address, setAddress] = useState('') 
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const create = async(ev) => {  //ev is what the person submits 
    ev.preventDefault()     // preventDefault stops the ? refresh behaviour
    await dispatch(createSchool({name, address})) 
    navigate('/schools')

  }
  return (
    <div>
      <h1> Creating a School </h1>
      <form onSubmit= { create } >
        <input placeholder='name here' value= { name } onChange= { (ev) => {setName(ev.target.value)} } />
        <input placeholder= 'address here' value= { address } onChange= { (ev) => {setAddress(ev.target.value)} } /> 
        <button disabled= { !name } > create </button>
      </form>
    </div>
  )
};

export default SchoolCreate;
