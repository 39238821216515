import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Schools = () => {
  const { schools, students } = useSelector(state => state) // this line is getting schools from the redux store
    
  return (
    <div>
      <h2>Schools List </h2>
      <ul>
        {
          schools.map(school => {
            const enrolled = students.filter(student => {
              return student.schoolId === school.id                            
            })
            console.log(enrolled)
            return (
            <li key = {school.id} >
              <Link to={`/schools/${school.id}`} > {school.name} </Link> {enrolled.length}
              </li>
              )
            })
        }
      </ul>
      <Link to="/schools/create">Create School</Link>
    </div>



    ) // inside the return 
};  // inside Schools 

export default Schools;