import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import axios from 'axios';





const schools = (state = [], action) => {
  if (action.type === 'SET_SCHOOLS') {
    state = action.schools
  }
  if (action.type === 'CREATE_SCHOOL') {
    state = [...state, action.school]    // this if statement lets the school link update with the new school right away 
  }           // this [] copies the old array and then adds the new one to the end  
  if (action.type === 'DESTROY_SCHOOL') {
    state = state.filter((school) => {
      return school.id !== action.school.id
    }) 
  }
  return state;
};
//this is a reducer function (THIS is what IS LISTENING FOR COMMANDS ((DISPATCH is the command the func is listening))  )

// state is never directly modified*** 



const students = (state = [], action) => {
  if (action.type === 'SET_STUDENTS') {
    state = action.students
  }
  if (action.type === 'ADD_STUDENT') {
    state = [...state, action.student]    
  }
  if (action.type === "DESTROY_STUDENT") {
    state = state.filter((student) => {
      return student.id !== action.student.id
    })
  }
  return state;
};

const courses = (state = [], action) => {
  if (action.type === 'SET_COURSES') {
    state = action.courses
  }
  if (action.type === 'CREATE_COURSE') {
    state = [...state, action.course]
  }
  return state;
};





export const createSchool = (creatingSchool) => {
  return async(dispatch)=> {
    console.log(creatingSchool)

    const response = await axios.post('/api/schools', creatingSchool);  
    
    const createdSchool = response.data 
    
    dispatch({ 
     type: 'CREATE_SCHOOL',   
      school: createdSchool
     }); 
  };
};


export const addStudent = (addingStudent) => {
  return async(dispatch)=> {

    const response = await axios.post('/api/students', addingStudent);

    const addedStudent = response.data

    dispatch({
      type: 'ADD_STUDENT',
      student: addedStudent
    });
  };
};


export const createCourse = (creatingCourse) => {
  return async(dispatch) => {
    const response = await axios.post('/api/courses', creatingCourse);
    const createdCourse = response.data

    dispatch({
      type: "CREATE_COURSE",
      course: createdCourse
    });
  };
};







export const destroyStudent = (student) => {
  return async(dispatch)=> {

    await axios.delete(`/api/students/${student.id}`);

    dispatch({
      type: 'DESTROY_STUDENT',
      student,
    });
  };
};

export const destroySchool = (school)=> {
  return async(dispatch) => {
    
    await axios.delete(`/api/schools/${school.id}`);  
    
    dispatch({ 
     type: 'DESTROY_SCHOOL',   
     school,
     }); 
  };
};











// WE call fetchSchools. redux calls dispatch
export const fetchSchools = ()=> {
  return async(dispatch)=> {
    
    const response = await axios.get('/api/schools');  
    
    const schools = response.data 
    //redux store changes by dispatching to the store 
    dispatch({ 
     type: 'SET_SCHOOLS',   
      schools,
     }); 
  };
};


export const fetchStudents = ()=> {
  return async(dispatch)=> {
    
    const response = await axios.get('/api/students');  
    
    const students = response.data 
    dispatch({ 
     type: 'SET_STUDENTS',   
      students,
     }); 
  };
};


export const fetchCourses = () => {
  return async(dispatch) => {
    const response = await axios.get('/api/courses');
    const courses = response.data
    dispatch({
      type: 'SET_COURSES',
      courses,
    })
  }
}



const reducer = combineReducers({ 
  schools, 
  students, 
  courses,
});

const store = createStore(reducer, applyMiddleware(thunk, logger));


export default store;
