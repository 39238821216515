import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { addStudent } from './store';


const StudentAdd = () => {
  const [firstName, setFirstName] = useState('') 
  const [lastName, setLastName] = useState('')
  const [gpa, setGPA] = useState(0) 
  const [school, setSchool] = useState('')  // useState takes in 1 arg - default value of the thing
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {schools} = useSelector(state => state)

  const create = async(ev) => {  //ev is what the person submits 
    ev.preventDefault()     // preventDefault stops the ? refresh behaviour
    await dispatch(addStudent({firstName, lastName, gpa})) 
    navigate('/students')

  }

  return (
    <div>
      <h1> Adding a Student </h1>
      <form onSubmit= { create } >
        <input placeholder='first name' value= { firstName } onChange= { (ev) => {setFirstName(ev.target.value)}} />
        <input placeholder='last name' value= { lastName } onChange= { (ev) => {setLastName(ev.target.value)}} />
        
        <input placeholder='gpa' value= { gpa } onChange= { (ev) => {setGPA(ev.target.value)}} />
        <select value= {school} onChange= {(ev) => setSchool(ev.target.value)} >
          <option hidden >
            Please select school:
          </option>
          { schools.map((school) => {
            return <option value={school.id} key={school.id}> 
             { school.name }  
            </option>
          })}
          { // this is is a dropdown menu (doesn't save data yet)
           } 

        </select>
        <button disabled= { !firstName } > create </button>
      </form>
    </div>
  )
};

export default StudentAdd;