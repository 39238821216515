import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Routes } from 'react-router-dom';
import CourseCreate from '././CourseCreate';

const Courses = () => {
  const { courses } = useSelector(state => state) 
  console.log(courses)
  return (
    <div>
      <h2>Courses List</h2>
      <ul> {
      courses.map(course => <li key= {course.id} > <Link to={`/courses/${course.id}`}> {course.name} </Link> </li>)
      }
      </ul>
      <Link to='/courses/create'> Create New Course </Link>
      <Link to='/courses/register'> Register for a Course </Link>
    </div>

  )
};  
export default Courses;