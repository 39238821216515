import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSchools, fetchStudents, fetchCourses } from './store';
import { Link, Routes, Route } from 'react-router-dom';
import Students from './Students';
import Student from './Student';
import Schools from './Schools';
import School from './School';
import SchoolCreate from './SchoolCreate';
import StudentAdd from './StudentAdd';
import Courses from './Courses';
import CourseCreate from './CourseCreate';
import CourseRegister from './CourseRegister';
import Course from './Course.js';

const App = ()=> {        // useSelector is subscribing to redux store (gets updates when it changes)
  const { foo, schools, students } = useSelector(state => state);
  const dispatch = useDispatch();
  useEffect(()=> {
    dispatch(fetchSchools());
    dispatch(fetchStudents());
    dispatch(fetchCourses());
  }, []);

  return (
    <div>
      <h1>JPFP 2212 { foo } </h1>
      <Link to='/schools'> Schools {schools.length} </Link>
      <Link to="/students"> Students {students.length} </Link>
      <Link to='/courses'> Courses </Link>
      <Routes>
        <Route path='/students' element={ <Students /> } />

        <Route path='/students/:id' element= { <Student /> } />  

        <Route path='/schools' element={ <Schools /> } />

        <Route path='/schools/:id' element={ <School /> } />

        <Route path='/schools/create' element={ <SchoolCreate />  } />

        <Route path='/students/add' element={ <StudentAdd /> } />

        <Route path='/courses/' element={ <Courses /> } /> 

        <Route path='/courses/create' element={ <CourseCreate /> } /> 

        <Route path='/courses/register' element={ <CourseRegister /> } />

        <Route path='/courses/:id' element={ <Course /> } />

      </Routes>
    </div>
  );
};

export default App;
