import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { createCourse } from './store';



const CourseRegister = () => {

  

  return (
    <div>
      <h2> Registering for a Course </h2>
      <ul>
        <li> Feature under construction! </li>
      </ul>
    </div>
  )
}

export default CourseRegister;